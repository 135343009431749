/*
 * Copyright (C) 2022 CONTACT Software GmbH
 * All rights reserved.
 * http://www.contact-software.com
 */

// Collected here are some global cs.web variables such as default colors and
// padding/margin sizes that should be the same all across the contact themes.
// Note that these values are taken straight from cs.web.

/// === Theming ===

// default product colors, overwrite for product branding
$product-primary-lightest: #e4f1f8 !default;
$product-primary-lighter: #73b9df !default;
$product-primary-light: #2e97cf !default;
$product-primary: #0080c5 !default;
$product-primary-dark: #0069a2 !default;
$product-primary-darker: #00466c !default;

// set background and logo data; may be overwritten in themes
$background-url: url("../img/Elements_Umbrella_Release_15_7_3840x2160px.webp") !default;
$app-bar-logo: url("../img/contact.svg") !default;
$app-bar-height: 2.44rem;
$logo-height: 150px !default;

// misc
$form-base-width: 300px;
$locale-dropdown-width: 8rem;

// padding and border width add to an element's base width so we need to
// subtract them for form element to be the same width as the form
$form-input-width: calc($form-base-width - 2 * 0.5rem - 2px);

/// === Colors ===

// elements color palette
$gray-lightest: #f7f7f7 !default;
$gray-lighter: #e5e5e5 !default;
$gray-light: #bfbfbf !default;
$gray: #999999 !default;
$gray-dark: #727272 !default;
$gray-darker: #191919 !default;

$white-base: #ffffff !default;

$elements-primary-lightest: #e4f1f8 !default;
$elements-primary-lighter: #73b9df !default;
$elements-primary-light: #2e97cf !default;
$elements-primary: #0080c5 !default;
$elements-primary-dark: #0069a2 !default;
$elements-primary-darker: #00466c !default;

$elements-success-lightest: #f6f9e4 !default;
$elements-success-lighter: #d2e175 !default;
$elements-success-light: #bbd230 !default;
$elements-success: #adc902 !default;
$elements-success-dark: #8ea502 !default;
$elements-success-darker: #5f6e02 !default;

$elements-info-lightest: #fef7e4 !default;
$elements-info-lighter: #fdd873 !default;
$elements-info-light: #fcc52e !default;
$elements-info: #fcb900 !default;
$elements-info-dark: #ce9800 !default;
$elements-info-darker: #8a6500 !default;

$elements-warning-lightest: #feede4 !default;
$elements-warning-lighter: #faa675 !default;
$elements-warning-light: #f87930 !default;
$elements-warning: #f75c03 !default;
$elements-warning-dark: #cb4c03 !default;
$elements-warning-darker: #873302 !default;

$elements-danger-lightest: #fae4e4 !default;
$elements-danger-lighter: #e57373 !default;
$elements-danger-light: #d82e2e !default;
$elements-danger: #d00000 !default;
$elements-danger-dark: #ab0000 !default;
$elements-danger-darker: #720000 !default;

/// === Scaffolding ===

// global styles to be applied to <body>
$body-bg: $white-base;
$text-color: $gray-darker !default;
$link-color: $elements-primary !default;

// link hover color set via `darken()` function
$link-hover-color: $elements-primary-dark !default;

/// === Typography ===

// Source Sans Pro font family
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/sourcesanspro-regular-webfont.woff2");
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/sourcesanspro-bold-webfont.woff2");
  font-weight: bold;
}

// font sizes
$font-size-base: 14px !default;
$font-size-large: 20px !default;
$font-size-small: 12px !default;
$font-size-h1: 22px !default;
$font-size-h2: 20px !default;
$font-size-h3: 16px !default;
$font-size-h4: $font-size-base !default;
$font-size-h6: $font-size-small !default;

/// === Components ===

$border-radius-base: 2px !default;
$border-radius-large: 4px !default;
$border-radius-small: 2px !default;

$icon-size-sm: 16px !default;
$icon-size-md: 32px !default;
$icon-size-lg: 64px !default;

$button-size-sm: 20px;
$button-size-md: 24px;
$button-size-lg: 32px;

$margin-xs: 0.2rem !default;
$margin-sm: 0.5rem !default;
$margin-md: 1rem !default;
$margin-lg: 2rem !default;

$padding-sm: 0.5rem !default;
$padding-md: 1rem !default;
$padding-lg: 2rem !default;
