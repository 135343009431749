@import "variables";

@mixin ce-text-input {
  border-radius: $border-radius-base;
  border: 1px solid $gray;
  padding: 4px 6px;
  color: $text-color;
  box-shadow: none;
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  width: 100%;

  &:active,
  &:focus {
    border: 1px solid $product-primary;
  }

  &:read-only {
    background-color: $gray-lightest;
    border-color: $gray-lighter;
  }
}

@mixin ce-text-input-label {
  color: $gray-dark;
  margin-bottom: unset;
}

@mixin ce-btn {
  border-radius: $border-radius-large;
  padding: $padding-sm;
  font-weight: bold;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  cursor: pointer;
}

@mixin ce-btn-primary {
  @include ce-btn;
  color: $white-base;
  background-color: $product-primary;
  border: 1px solid $product-primary;

  &:hover {
    background-color: $product-primary-darker;
    border-color: $product-primary-darker;
  }

  &:disabled {
    background-color: $gray-dark;
    border-color: $gray-dark;
  }
}

@mixin ce-btn-secondary {
  @include ce-btn;
  color: $product-primary;
  background-color: $white-base;
  border: 1px solid $gray-light;

  &:hover {
    background-color: $gray-lighter;
  }
}

.kc-con-form-control,
.form-control {
  @include ce-text-input;
}

.kc-con-form__label,
.control-label {
  @include ce-text-input-label;
}
