@import "variables";

body,
html {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  background-color: $body-bg;
  color: $text-color;
  font-family: "Source Sans Pro", sans-serif;

  a {
    color: $link-color;
    text-decoration: none;

    &:hover,
    &:active {
      color: $link-hover-color;
    }
  }
}

html,
input {
  font-size: $font-size-base;
}

hr {
  border: 1px solid $gray-dark;
}

.center-text {
  text-align: center;
}

@mixin text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.alert-info {
  background-color: $product-primary-lightest;
  border: 1px solid $product-primary-dark;
  color: $product-primary-darker;
}

.alert-warning {
  background-color: $elements-warning-lightest;
  border: 1px solid $elements-warning-dark;
  color: $elements-warning-darker;
}

.alert-error {
  background-color: $elements-danger-lightest;
  border: 1px solid $elements-danger-dark;
  color: $elements-danger-darker;
}

.alert-success {
  background-color: $elements-success-lightest;
  border: 1px solid $elements-success-dark;
  color: $elements-success-darker;
}

#kc-info-wrapper {
  background-color: inherit;
  margin: $margin-md 0;
  color: $gray-darker;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.avoid-wrap {
  display: inline-block;
}

#kc-locale {
  background-color: $white-base;
  color: $product-primary;
  border: 1px solid $product-primary;
  height: fit-content;
  width: $locale-dropdown-width;
  margin: $margin-lg;

  a {
    color: $product-primary;

    &:hover,
    &:active {
      color: $link-hover-color;
    }
  }

  #kc-current-locale-link {
    padding: $padding-sm;
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 8rem;

    &::before {
      width: 1.3rem;
      height: 1.3rem;
      margin-right: 0.3rem;
      vertical-align: -20%;
      background-image: url("../img/Globe.svg");
      display: inline-block;
      content: "";
    }

    &::after {
      width: 0;
      height: 0;
      border-top: $product-primary 0.3rem solid;
      border-right: transparent 0.3rem solid;
      border-left: transparent 0.3rem solid;
      border-bottom: transparent 0.3rem solid;
      margin-left: $margin-sm;
      vertical-align: middle;
      display: inline-block;
      content: "";
    }
  }

  #kc-locale-dropdown button {
    color: inherit;
  }

  .kc-con-locale-list {
    list-style: none;
    padding-left: 0;
    margin: 15px 0 0 -1px;
    position: absolute;
    width: $locale-dropdown-width;
    background-color: $white-base;
    border: 1px solid $product-primary;
    border-top: none;
    display: none;

    &__visible {
      display: inline-block !important;
    }

    > li {
      font-weight: normal;

      &:hover {
        background-color: $product-primary-lightest;
      }

      > a {
        display: inline-block;
        height: calc(100% - 2 * $padding-sm);
        color: $gray-darker;
        padding: $padding-sm 0 $padding-sm $padding-sm;
        text-align: left;
        width: 100%;

        &:focus {
          outline: none;
        }
      }
    }
  }
}

#kc-locale-dropdown {
  display: unset;
  &:hover ul {
    display: none;
  }
}
